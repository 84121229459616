import parsePhoneNumberFromString from "libphonenumber-js";
import moment from "moment";
import {MoveDown, MoveUp} from "lucide-react";

export const formatPhoneNumber = (phoneNumber: string) => {
    const phoneNumberObject = parsePhoneNumberFromString(phoneNumber);

    if (!phoneNumberObject) {
        return phoneNumber; // Return the original input if parsing fails
    }

    const countryCode = phoneNumberObject.countryCallingCode;
    const nationalNumber = phoneNumberObject.formatNational().replace(/\s+/g, '-');

    return `+${countryCode}-${nationalNumber}`;
};

export const formatPhone = (phone?: string) => {
    if (!phone) {
        return phone;
    }
    return formatPhoneNumber(phone);
}

export const formatNumber = (value: any) => {
    const number = parseFloat(value) ?? 0;
    if (isNaN(number)) {
        return '--';
    }
    const formattedNumber = new Intl.NumberFormat('en-US').format(number);

    return (
        formattedNumber
    );
};

export const friendlyDate = (val?: string, format?: string) => {
    if (!val) {
        return val;
    }
    format = format || 'LLL';
    try {
        //create a moment date with string
        let date = new Date(val);
        if (format) {
            //check if date has hours, minutes, seconds
            if (val.length === 10) {
                let m = moment.utc(val, "YYYY-MM-DD");
                m = m.local();
                return m.format(format);
            }
            let m = moment.utc(val, "YYYY-MM-DD HH:mm:ss");
            m = m.local();
            return m.format(format);
            // return moment(date).format(format);
        }
        return date.toLocaleString();
    } catch (e) {
        return val;
    }

}

export const tableSortIconHeaderArrow = (desc: boolean) => {
    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }
    }> {desc ? <MoveDown size={12}/> : <MoveUp size={12}/>}</div>;
}

export const sortItems = (sortBy: string, items: any) => {
    
    if (sortBy == null) {
        return;
    }

    let direction = sortBy.endsWith('_desc') ? -1 : 1;
    let field = sortBy.replace('_desc', '');

    //sort by field, case insensitive, if numbers, sort by number
    items.sort((a: any, b: any) => {
            if (typeof a[field] === 'number' && typeof b[field] === 'number') {
                return (a[field] - b[field]) * direction;
            }
            if (a[field]?.toString().toLowerCase() < b[field]?.toString().toLowerCase()) {
                return -1 * direction;
            }
            if (a[field]?.toString().toLowerCase() > b[field]?.toString().toLowerCase()) {
                return direction;
            }
            return 0;
        }
    );
    //
    // items.sort((a: any, b: any) => {
    //     if (a[field]?.toString().toLowerCase() < b[field]?.toString().toLowerCase()) {
    //         return -1 * direction;
    //     }
    //     if (a[field]?.toString().toLowerCase() > b[field]?.toString().toLowerCase()) {
    //         return direction;
    //     }
    //     return 0;
    // });
}