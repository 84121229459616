// export const WEBSITE_URL = 'http://M1-2.local:8889';

// export const BACKEND_API = 'http://M1-2.local:8889/__api/cms/v2';
export const BACKEND_API = 'https://xa.yapysiad.com/__api/cms/v2';
export const WEBSITE_URL = 'https://daisypay.io';
export const BACKEND_COOKIE_ID = 'DSY_CMS_TOKEN';

export class DSYPlatforms {
    static TIKTOK = 1;
    static INSTAGRAM = 2;
}

export const friendlyPlatformName = (platform: number) => {
    switch (platform) {
        case DSYPlatforms.TIKTOK:
            return 'TikTok';
        case DSYPlatforms.INSTAGRAM:
            return 'Instagram';
        default:
            return 'Unknown';
    }
}

export const friendlyPlatformImage = (platform: number) => {
    switch (platform) {
        case DSYPlatforms.TIKTOK:
            return '/images/tiktok-color.svg';
        case DSYPlatforms.INSTAGRAM:
            return '/images/instagram-color.svg';
        default:
            return '/images/unknown.png';
    }
}
export const friendlyCampaignActionName = (slug: string) => {

    switch (slug) {
        case 'like_and_comment':
            return 'Like & Comment';
        case 'repost':
            return 'Share to Story';
        case 'repost_on_account_page':
            return 'Repost';
        default:
            return 'Unknown';

    }
}