import {useState} from "react";
import {useParams} from "react-router-dom";

export function ReportsPage() {
    const [didLoad, setDidLoad] = useState(true);
    const [htmlString, setHtmlString] = useState('');
    const {campaignsUUID} = useParams();

    let source = `https://daisypay.io/__back_team_cms__/reports/campaign/?uuid=` + campaignsUUID + `&new_cms=11`;
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    if (params.has('offapp')) {
        source = `https://daisypay.io/__back_team_cms__/reports/campaign-off-app/?uuid=` + campaignsUUID + `&new_cms=11`;
    }
    if (!didLoad) {
        return (
            <div>Loading...</div>
        );
    }

    return (
        <>
            <iframe
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none'
                }}
                src={source} width="100%" height="1000px"/>
        </>
    );
}