import {useEffect, useState} from "react";
import {DSYForm, DSYTable} from "../../styles/GlobalStyles";
import {fetchSponsors} from "../../services/apiSponsors";
import useOnceEffect from "../../hooks/userOnceEffect";
import {serviceUpdateCampaignOffApp} from "../../services/apiCampaignsOffAppService";


export function CampaignsOffAppEditPage({campaign, onCloseCallback, onUpdateItem}: {
    campaign: any,
    onCloseCallback: Function,
    onUpdateItem: Function
}) {

    const [sponsors, setSponsors] = useState<any>([]);
    const [didLoadData, setDidLoadData] = useState(false);
    const [values, setValues] = useState<any>({});

    let sectionsToEdit = [
        {'title': 'Name', 'input_type': 'text', 'value': '', 'name': 'name'},
        {'title': 'Platform', 'input_type': 'radio', 'value': '', 'name': 'platforms_id'},
        {'title': 'URL', 'input_type': 'text', 'value': '', 'name': 'url'},
        {'title': 'Image', 'input_type': 'file', 'accept_types': 'image/*', 'value': '', 'name': 'image'},
        {'title': 'Start Date (Time Zone: EST)', 'input_type': 'datetime-local', 'value': '', 'name': 'date_start_EST'},
        {'title': 'End Date (Time Zone: EST)', 'input_type': 'datetime-local', 'value': '', 'name': 'date_end_EST'},
        {'title': 'Active', 'input_type': 'checkbox', 'value': 'checked', 'name': 'active'},

    ]
    const isNewItem = campaign.hasOwnProperty('uuid') && campaign.uuid === 'new';

    const getSponsors = async () => {
        try {
            const data = await fetchSponsors();
            setSponsors([]);
            if (data.hasOwnProperty('items')) {
                let items = data['items'];
                setSponsors(items);
            }

        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useOnceEffect(() => {
        getSponsors().then();
    });

    useEffect(() => {
        let data: any = {};

        sectionsToEdit.forEach((section) => {
            //if name has a dot, it's a nested object
            if (section.name.includes('.')) {
                let parts = section.name.split('.');
                let first = parts[0];
                let second = parts[1];
                if (campaign[first] != null) {
                    data[first + '.' + second] = campaign[first][second];
                }
            } else {
                data[section.name] = campaign[section.name];

            }
        });
        setValues(data);
        setDidLoadData(true);

    }, []);


    const handleCancel = (e: any) => {
        e.preventDefault();
        onCloseCallback();
    }

    const handleSave = async (e: any) => {
        e.preventDefault();

        let selectedPlatform = parseInt(values.platforms_id, 10);
        //validation
        //if platform_id == 1 (tiktok), url must start with https://www.tiktok.com/@ , if https://vm.tiktok.com/ , show error about expected url
        if (values.url != null && values.url.toString().trim().length > 4) {
            if (selectedPlatform === 1 && !values.url.startsWith('https://www.tiktok.com/@')) {
                //if empty, it's okay
                if (values.url.toString().trim() === '') {
                } else {
                    alert('TikTok URL must start with https://www.tiktok.com/@');
                    return;
                }
            }
            // if platform_id == 2 (instagram), url must start with https://www.instagram.com/ , if https://www.instagram.com/p/ , show error about expected url
            if (selectedPlatform === 2 && !values.url.startsWith('https://www.instagram.com/')) {
                if (values.url.toString().trim() === '') {
                } else {
                    alert('Instagram URL must start with https://www.instagram.com/');
                    return;
                }

            }
        }
        let sendValues = {...values};
        sendValues.uuid = campaign.uuid;
        console.table(sendValues);

        var result = await serviceUpdateCampaignOffApp(campaign.uuid, sendValues);
        console.log('saved', result);
        if (result.status === 'error') {
            alert(result.message);
        } else {
            campaign = {...campaign, ...values};
            onUpdateItem(campaign);
            if (isNewItem) {
                onCloseCallback({'reload': true});
            } else {
                onCloseCallback();
            }
        }
    }

    if (!didLoadData) {
        return <>
            Loading...
        </>
    }


    return (
        <div>
            <div style={{
                display: 'flex',
                gap: 20,
                alignItems: 'center',
                marginBottom: 20
            }}>
            </div>
            <h2>Edit </h2>
            <DSYForm onSubmit={handleSave}>
                <DSYTable>
                    {sectionsToEdit.map((section, index) => {
                        let inner = null;
                        if (section.input_type === 'checkbox') {
                            inner = <input type={section.input_type} name={section.name}
                                           checked={values[section.name]}
                                           onChange={(e) => {
                                               setValues({...values, [section.name]: e.target.checked})
                                           }}/>

                        } else if (section.input_type === 'file') {
                            return null;
                        } else if (section.input_type === 'select') {
                            if (section.name === 'single_action_kind') {
                                let options = [
                                    {'title': 'Like & Comment', 'value': 'like_and_comment'},
                                    {'title': 'Share to Story', 'value': 'repost'},
                                    {'title': 'Repost', 'value': 'repost_on_account_page'},
                                ];
                                inner = <>
                                    <select name={section.name} value={values[section.name]}
                                            onChange={(e) => {
                                                setValues({...values, [section.name]: e.target.value})
                                            }}>
                                        {options.map((option, index) => {
                                            let selected = values[section.name] === option.value;
                                            return <option key={index}
                                                           selected={selected}
                                                           value={option.value}>{option.title}</option>
                                        })}
                                    </select>
                                </>
                            } else if (section.name === 'sponsor.uuid') {
                                let options = sponsors;
                                inner = <>
                                    <select name={section.name} value={values[section.name]}
                                            onChange={(e) => {
                                                setValues({...values, [section.name]: e.target.value})
                                            }}>
                                        {options.map((option: any, index: number) => {
                                            let selected = values[section.name] === option.uuid;
                                            return <option key={index}
                                                           selected={selected}
                                                           value={option.uuid}>{option.name}</option>
                                        })}
                                    </select>
                                </>
                            }
                        } else if (section.input_type === 'radio') {

                            if (section.name === 'platforms_id') {
                                let options = [
                                    {'title': 'TikTok', 'value': 1},
                                    {'title': 'Instagram', 'value': 2},
                                ];
                                inner = <>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 20
                                    }}>
                                        {options.map((option, index) => {
                                            let selected = parseInt(values[section.name], 10) === option.value;
                                            return <div key={index} style={{
                                                display: 'flex',
                                                gap: 10,
                                                alignItems: 'center',
                                            }}>
                                                <input type={section.input_type} name={section.name}
                                                       value={option.value}
                                                       checked={selected}
                                                       onChange={(e) => {
                                                           setValues({
                                                               ...values,
                                                               [section.name]: parseInt(e.target.value, 10)
                                                           })
                                                       }}/>
                                                <div>
                                                    {option.title}
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </>
                            } else {

                                inner = <input type={section.input_type} name={section.name}
                                               value={values[section.name]}
                                               onChange={(e) => {
                                                   setValues({...values, [section.name]: e.target.value})
                                               }}/>
                            }
                        } else {


                            inner = <input type={section.input_type} name={section.name}
                                           value={values[section.name]}
                                           onChange={(e) => {
                                               setValues({...values, [section.name]: e.target.value})
                                           }}/>
                        }


                        return (
                            <tr key={index}>
                                <td>{section.title}</td>
                                <td>
                                    {inner}
                                </td>
                            </tr>
                        );
                    })}
                </DSYTable>
                <div className={'cta-area'}>
                    <a onClick={handleCancel}>Cancel</a>
                    <button type="submit">Submit</button>
                </div>
            </DSYForm>
        </div>
    );
}