import {apiClient} from "./apiService";

export const servciceFetchCampaignsOffApp = async () => {
    const response = await apiClient.get('/campaigns-off-app/all');
    return response.data;
};

export const serviceUpdateCampaignOffApp = async (id: string, userData: any) => {
    const response = await apiClient.post(`/campaigns-off-app/update/${id}`, userData);
    return response.data;
};


export const serviceUpdateCampaignOffAppProperties = async (id: string, keyValue: object) => {
    const response = await apiClient.post(`/campaigns-off-app/update-properties/`, {
        'uuid': id,
        'keyValue': keyValue
    });
    return response.data;
}